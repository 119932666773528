import { computed, ref } from 'vue';
import type {
  ImportedContacts,
  ProviderImportedContact,
  SuccessSetImportedContactsResponseData,
  EmailProvider,
  PhoneContact,
} from '@/types';
import { useArray } from '@/composables';
import { extractNumber, phoneMasked } from '@/utils';

export function useImportedContacts() {
  const importedContacts = ref<ImportedContacts[]>([]);
  const emailsProviderSynced = ref<EmailProvider[]>([]);

  const countImportedContactsByProvider = computed(() => {
    return (provider: ProviderImportedContact) => {
      return importedContacts.value.filter(
        (contact) => contact.provider === provider,
      ).length;
    };
  });

  function getImportedContact(
    contact: SuccessSetImportedContactsResponseData,
  ): ImportedContacts {
    return {
      id: contact.id,
      userId: contact.user_id,
      emails: contact.emails,
      firstName: contact.first_name,
      lastName: contact.last_name,
      phones: contact.phones,
      provider: contact.provider,
      emailSourceProvider: contact.email_source_provider,
      jobTitle: contact.job_title,
      company: contact.company,
      country: contact.country,
      state: contact.state,
      street: contact.street,
      city: contact.city,
      postcode: contact.postcode,
      type: contact.type as 'imported' | 'local',
    };
  }

  function setImportedContacts(
    contact: SuccessSetImportedContactsResponseData,
  ) {
    importedContacts.value.push(getImportedContact(contact));
  }

  function updateContact(
    id: number,
    contact: SuccessSetImportedContactsResponseData,
  ) {
    const { findIndexByValue } = useArray(importedContacts.value);

    const index = findIndexByValue('id', id);

    if (index !== -1) {
      importedContacts.value[index] = getImportedContact(contact);
    }
  }

  function deleteContact(id: number) {
    const { findIndexByValue, removeElementByIndex } = useArray(
      importedContacts.value,
    );

    const index = findIndexByValue('id', id);

    if (index !== -1) {
      removeElementByIndex(index);
    }
  }

  function sortImportedContactsByNameAsc() {
    importedContacts.value.sort((a, b) =>
      a.firstName.localeCompare(b.firstName),
    );
  }

  function setEmailProviderAccount(
    provider: ProviderImportedContact,
    email: string,
  ) {
    const emailProvider = getEmailProviderAccount(provider);
    if (emailProvider === undefined) {
      return emailsProviderSynced.value.push({
        provider,
        email,
      });
    }

    emailProvider.email = email;
  }

  function getEmailProviderAccount(
    provider: ProviderImportedContact,
  ): EmailProvider | undefined {
    return emailsProviderSynced.value.find(
      (item) => item.provider === provider,
    );
  }

  function setSourceEmailProvider() {
    emailsProviderSynced.value = [];

    for (const { emailSourceProvider, provider } of importedContacts.value) {
      const hasEmailSourceProvider = emailsProviderSynced.value.find(
        (emailProvider) => emailProvider.provider === provider,
      );

      if (!hasEmailSourceProvider) {
        emailsProviderSynced.value.push({
          email: emailSourceProvider,
          provider,
        });
      }
    }
  }
  function removeImportedContactsByProvider(provider: ProviderImportedContact) {
    importedContacts.value = importedContacts.value.filter(
      (contact) => contact.provider !== provider,
    );
    setSourceEmailProvider();
  }

  const getImportedContactsById = computed(() => {
    return (id: number) => {
      return importedContacts.value.find((contact) => contact.id === id);
    };
  });

  function setImportedContactsFavorite(id: number) {
    const index = importedContacts.value.findIndex(
      (contact) => contact.id === id,
    );

    if (index !== -1) {
      importedContacts.value[index].favorite = true;
    }
  }

  const favoriteImportedContacts = computed(() =>
    importedContacts.value.filter((contact) => contact.favorite),
  );

  const findImportedContactByNumber = computed(() => {
    return (number: string) => {
      const contacts: {
        id: ImportedContacts['id'];
        phone: { tag: string; number: string; fullName: string };
      }[] = [];
      for (const contact of importedContacts.value) {
        const fullName = `${contact.firstName} ${contact.lastName}`.trim();
        if (!contact.phones) {
          continue;
        }
        for (const phone of contact.phones) {
          contacts.push({
            id: contact.id,
            phone: { ...phone, fullName },
          });
        }
      }
      return contacts.find(
        (contact) =>
          phoneMasked(extractNumber(contact.phone.number)) ===
          phoneMasked(extractNumber(number)),
      );
    };
  });

  return {
    importedContacts,
    countImportedContactsByProvider,
    emailsProviderSynced,
    getImportedContactsById,
    setImportedContacts,
    setEmailProviderAccount,
    getEmailProviderAccount,
    removeImportedContactsByProvider,
    setSourceEmailProvider,
    sortImportedContactsByNameAsc,
    setImportedContactsFavorite,
    favoriteImportedContacts,
    updateContact,
    deleteContact,
    findImportedContactByNumber,
  };
}
